import React, { useEffect, useState } from "react";
import "./Pictures.css"
import styled from "styled-components";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import {Menu, WorkOutlineOutlined, OndemandVideo, CollectionsOutlined} from '@material-ui/icons/';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Pictures = () =>{        
    var    isClosed = false;
        
    function hamburger_cross() {
        var overlay = document.getElementById('overlay')
        var hamburger =  document.getElementById('hamburger');
        var pageContent =  document.getElementById('page-content-wrapper');
            
        if (isClosed !== true) {          
            overlay.style.display = "none";
            hamburger.classList.remove('is-closed');
            hamburger.classList.add('is-open');
            isClosed = true; 
            // pageContent.style.width = "85vw";
            pageContent.style.opacity = "0.5";

            pageContent.style.backgroundColor = "whitesmoke";
            // pageContent.style.marginLeft = "13vw";
            hamburger.style.marginLeft = "300px";
            
        } else { 
            overlay.style.display = "block";
            hamburger.classList.remove('is-open');
            hamburger.classList.add("is-closed");
            isClosed = false;
            pageContent.style.backgroundColor = "white";
                        // pageContent.style.padding = "2vw";
            // pageContent.style.marginLeft = "0";
             hamburger.style.marginLeft = "0";
            pageContent.style.opacity = "1";
        }
        disa()
    }
        
    const disa = () => {
        var wrapper = document.getElementById('wrapper');
        wrapper.classList.toggle('toggled');
    } 

    return (
        <Album>  
            
    <div id="wrapper">
        
    
        <nav className="navbar navbar-inverse fixed-top " id="sidebar-wrapper" role="navigation">
        <div className="overlay" id="overlay"></div>
            <ul className="nav sidebar-nav">
            <div className="sidebar-header">
            <div className="sidebar-brand">
            <Name>STIG </Name></div></div>
                <li>Gender: <Value>Male</Value></li>
                <li>Height: <Value>6'2" ( 190cm )</Value></li>
                <li>Suit Size: <Value> 40 ( 102cm )</Value></li>
                <li>Suit Length: <Value>R</Value></li>
                <li>Shirt Size: <Value>16 ( 41cm )</Value></li>
                <li>Sleeve: <Value>32 ( 81cm )</Value></li>
                <li>Waist: <Value>32 ( 81cm )</Value></li>
                <li>Inseam: <Value>31 ( 79cm )</Value></li>
                <li>Shoe Size: <Value>12 (US)</Value></li>
                <li>Hair Color: <Value>DK.BROWN</Value></li>
                <li>Eye Color: <Value>DK.BROWN</Value></li>
                
           </ul>
        </nav>

        <div id="page-content-wrapper">
        <button type="button" id="hamburger" className="hamburger animated fadeInLeft is-closed"
            onClick={hamburger_cross} data-toggle="offcanvas">
                <span className="hamb-top"></span>
    			<span className="hamb-middle"></span>
				<span className="hamb-bottom"></span>
            </button>
            {/* <TripleImage>
                <img src='assets/images/mtl2.WebP' alt = "Me again" />
                <img src='assets/images/mtl3.WebP' alt = "Me again"/>
                <img src='assets/images/mtl.WebP' alt = "Me again" />
            </TripleImage>  */}
            <Gallery className="row" id="gallery">
                <div className="col-lg-3 col-6 p-0">
                    <LazyLoadImage
                        src="assets/images/1.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Black on Black"
                          effect="blur" height={"auto"} width={"auto"}              

                          
                        
                       
                    />

                    <LazyLoadImage
                        src="assets/images/3.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/12.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/11.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/4.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />
                </div>

                <div class="col-lg-3 col-6 p-0">

                    <LazyLoadImage
                        src="assets/images/5.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/2.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Black on Black"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/8.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />
                    <LazyLoadImage
                        src="assets/images/15.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/6.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />
                </div>

                <div class="col-lg-3 col-6 p-0">
                    
                    <LazyLoadImage
                        src="assets/images/22.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Loading"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/7.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/16.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/14.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/9.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />
                </div>

                <div class="col-lg-3 col-6 p-0">
                    <LazyLoadImage
                        src="assets/images/19.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Loading"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/18.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/17.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/20.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />

                    <LazyLoadImage
                        src="assets/images/10.WebP"
                        class="w-100 shadow-1-strong rounded p-3"
                        alt="Illest Nigga"
                          effect="blur" height={"auto"} width={"auto"}              

                    />
                </div>
            </Gallery>
        </div>

    </div>
            {/* <Top>
                
            </Top>
            <FullImage>
                <img src='/assets/images/welcome.jpeg' alt="ME" />
            </FullImage>

            <TripleImage>
                <img src='assets/images/mtl2.WebP' alt = "Me again" />
                <img src='assets/images/mtl3.WebP' alt = "Me again"/>
                <img src='assets/images/mtl.WebP' alt = "Me again" />
            </TripleImage> 

             <DoubleImage>
                <img src='assets/images/playboyStig.WebP' alt = "Moi" />
                <img src='assets/images/playboyStig2.WebP' alt = "J'e" />
            </DoubleImage> 
            
           
            <WideImage>
                <img src="assets/images/trapMontage.jpeg"/>
            </WideImage>
           
           
            {/* <TripleImage>
                <img src='assets/images/stigBrown.WebP' alt = "Me again" className="col-6"/>
                <img src='assets/images/stigBrown2.WebP' alt = "Me again" className="col-6"/>
                <img src='assets/images/stigBrown2.WebP' alt = "Me again" className="col-6"/>
            </TripleImage> */}
            {/* <ImageTwo>
                
            </ImageTwo>         */}
            {/* <GalleryWrapper className="row">
                <SideBar className="col-2">

                </SideBar>
                <Gallery className="col-10">
                    <Row>
                        
                    </Row>
                </Gallery>
            </GalleryWrapper>  */}
        </Album>
             
    )
}

export default trackWindowScroll(Pictures);
const Value = styled.span`
    font-weight: bold;
`;
const Name = styled.h1`
    font-size: 100pt;
    font-family: Japanese2020;
    color:black;
`

const Album = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) { 
        margin:0;
    }
    background-color: white;
    border: 1px soild blue;
`
const Gallery = styled.div`
    
`;
