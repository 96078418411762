import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from "react-router-dom";
import { titles, projects, icons } from './data';
import {Transition} from 'react-transition-group';
import $ from 'jquery'
import {GitHub, YouTube, NightsStaySharp, PlayCircleOutline, PauseCircleOutline, } from '@material-ui/icons/';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }
 const Landing = () => {

    const [currentTitle, setCurrentTitle] = useState("Chinemerem Ezeakudolu");
    const [isplaying, setIsPlaying] = useState(false);
    const [currentDescription, setCurrrentDescription] = useState("  A set of related web pages located under a single domain name, typically produced by a single person or organization in this case me, stigmontage.com is a platform to showcase/display his ideas, projects and his overall essence..")
    useEffect(() =>{
        showTitle()
        var bArray = [];
      // Define a size array, this will be used to vary bubble sizes
      var sArray = [1,2,3,4,5];
   
      // Push the header width values to bArray

      for (var i = 0; i < document.getElementById('bubbles').offsetWidth; i++) {
          bArray.push(i);
      }
       
      // Function to select random array element
      // Used within the setInterval a few times
      function randomValue(arr) {
          return arr[Math.floor(Math.random() * arr.length)];
      }
   
      // setInterval function used to create new bubble every 350 milliseconds
      setInterval(function(){
           
          // Get a random size, defined as variable so it can be used for both width and height
          var size = randomValue(sArray);
          // New bubble appeneded to div with it's size and left position being set inline
          // Left value is set through getting a random value from bArray
          const bubble = document.createElement("span");
          bubble.classList="individual-bubble";
          bubble.style = 'left: ' + randomValue(bArray) + 'px; width: ' + size + 'px; height:' + size + 'px;'
          document.getElementById('bubbles').appendChild(bubble);
           
          // Animate each bubble to the top (bottom 100%) and reduce opacity as it moves
          // Callback function used to remove finsihed animations from the page
          $('.individual-bubble').animate({
              'bottom': '100%',
              'opacity' : '0',
              'duration': 4
          }, 3000, function(){
              $(this).remove()
          }
          );
   
   
      }, 350);
    },[currentTitle, currentDescription])

      
    const showTitle = () =>{
        let interval =  setInterval(function() {
            randomer(); // it excutes every 5sec
            document.documentElement.style.setProperty('--logo-size', "5s");
         },5000);
         setTimeout(function( ) { clearInterval( interval ); }, 5000)
    }

    const randomer = () =>{
        let random = Math.floor(Math.random() * titles.length)

        if(titles[random].name !== currentTitle){
            setCurrentTitle(titles[random].name)
        }else if( currentTitle !== "Chinemerem"){
            setCurrentTitle("Chinemerem");
        }else{
            setCurrentTitle("Stig")
        }
       
    }
    const navigate = useNavigate();

    const handleWrapperClick = ()=>{
        let song = new Audio("assets/audio/Stig.mp3");
        song.play();
        navigate("/home");
    }
    const handleLoad = () =>{
        
    }



    return (
    <Wrapper>
        <Noise></Noise>
        <TopWrapper>
            <BackgroundVideo playsInline autoPlay loop disablePictureInPicture muted>
                <source src="assets/videos/stigMontage.mov" type="video/mp4"/>
                Your browser does not support HTML5 video.
                </BackgroundVideo>
            <h1>{currentTitle}</h1>
        </TopWrapper>
        <FadeInSection>
            <AboutWrapper>
                <AboutInfo className="row">
                    <AboutImage src="assets/images/aboutImage.JPG" alt="Chinemerem" className=" col-sm-12 col-xl-6"/>

                    <AboutText className="col-xl-6 col-sm-12">
                        <h3>ABOUT</h3>
                        <p>
                            On a hunt for more knowledge, skills and stories, Stig creates this online space to document his life achievements and stories, this life of his that he claims is a movie but has scripts to show for it, he believes it’s time to start production. Stig the Scientist specializes in developing web/mobile applications and artificial intelligence programs backed with the purpose of making life more pleasing to be in. 
                            Stig the Artist is a Director using the art of film making to express himself and act out his ideas clearly, this is backed up by his big brother persona to decpict how pleasing life can be.
                        </p>

                        
                        
                    </AboutText>
                </AboutInfo>
            </AboutWrapper> 
        </FadeInSection>

        <FadeInSection>
            <Projectwrapper>
                <Projects>
                    <ProjectDetail>
                        <ProjectHeader>
                            <h1>Chinemerem Ezeakudolu</h1>
                            <ProjectIcons>
                            
                                {
                                    isplaying ?  <PauseCircleOutline onClick={()=>setIsPlaying(!isplaying)}/>  :  <PlayCircleOutline onClick={()=>setIsPlaying(!isplaying)}/> 
                                }
                                <NightsStaySharp/>
                                <YouTube/>
                                <GitHub/>
                            </ProjectIcons>
                            
                        </ProjectHeader>
            
                        <ProjectDescription>
                            <h2>Description</h2>
                            <p>
                                {currentDescription}
                            </p>
                        </ProjectDescription>
                    </ProjectDetail>

                    <ProjectList>
                        <ProjectItemHeader>
                        <p style={{textAlign: "right"}}>Project (Design and Develop)</p>
                        </ProjectItemHeader>
                        
                        {
                            projects.map((project) =>{
                                return(
                                    <ProjectItem key={project.id} href={project.link} target="_blank" rel="noopener" onMouseEnter={()=>{setCurrrentDescription(project.description)}}>
                                        <ProjectTitle>{project.title}</ProjectTitle>
                                        <ProjectInfo>{project.year} / {project.category}</ProjectInfo>
                                    </ProjectItem>
                                );
                            })
                        }
                
                    </ProjectList>
                </Projects>
            </Projectwrapper>
        </FadeInSection>

        <FadeInSection>
        <ContactWrapper className='row'>
            <ContactHeader className="header header-large align-left">Contact.</ContactHeader>
            <Transition timeout={500}>
        
            <ContactDescription className='col-md-6' >
                <p>
                I'm open to collaborating and networking with like-minded individuals. If you're looking for someone to be a part of your next project, you can reach out to me on any of the following platforms.
                </p> 
            </ContactDescription>
            </Transition>

            <ContactDescription>
                <p>Email: <a href="mailto:ezeakudoluchinemerem@gmail.com">ezeakudoluchinemerem@gmail.com </a></p> 
                <p>
                    On the Internet: <a href="https://www.linkedin.com/in/david-ezeakudolu-11003117b/">LinkedIn </a> 
                    <a href="https://github.com/ChinemeremEze">Github </a> 
                    <a href="https://twitter.com/stigezehjunior">Twitter </a> 
                    <span id="bubbles"><Link to={"/links"}>Others</Link></span>
                </p>
            </ContactDescription>
            </ContactWrapper> 
        </FadeInSection>  
    </Wrapper>
    )
}
export default Landing;
const TopWrapper = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100vw;
    height: 100vh;
    background-color: #1a1919;
    color: #ece7e1;

    h1{
        margin-top: 0;
        display:flex;
        align-items: center;
        justify-content: center;
        min-height: 6vh;
        font-size: 4em;
        overflow:hidden;
        z-index: 1200;

    }
`;
const Wrapper = styled.div`

`;
const Noise = styled.div`
    position: fixed;
    z-index: 9;
    top: -300%;
    left: -150%;
    height: 600%;
    width: 600%;
    -webkit-animation: grain 7s steps(10) infinite;
    animation: grain 7s steps(10) infinite;
    background-image: url(assets/images/noise.png);
    background-size: 200px;
    background-repeat: repeat;
    opacity: .3;
    pointer-events: none;
    border: 2px solid red;
    

    @keyframes grain{
        0%, 100% {
            transform: translate(0);
        }
        10% {
            transform: translate(-5%,-10%);
        }
        20% {
            transform: translate(-15%,5%);
        }
        30% {
            transform: translate(7%,-25%);
        }
        40% {
            transform: translate(-5%,25%);
        }
        50% {
            transform: translate(-15%,10%);
        }
        60% {
            transform: translate(15%);
        }
        70% {
            transform: translateY(15%);
        }
        80% {
            transform: translate(3%,35%);
        }
        90% {
            transform: translate(-10%,10%);
        }
    }
`;

const BackgroundVideo = styled.video`
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    object-fit: cover;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
`;

const AboutWrapper = styled.div`
    padding: 2vh;
    background-image: radial-gradient(circle, #9265b0, #7f94d3, #89bce0, #b5dde8, #f0faf9);
    max-height: 100vh;
`;

const AboutInfo = styled.div`
    overflow: scroll;
    height: 96vh;
    display: flex;
    justify-content: center;
`;

const AboutImage = styled.img`
    padding: 10vh;
    display: inline-block;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(0 0 0.75rem crimson);
    border-radius: 50%;  
`;

const AboutText = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    padding: 0 10%;
    color: #1a1919;
    p{
        margin-bottom: 2vh;
        animation: fadeIn 5s; 

    }

    h3{ 
        text-align:center;
        font-size: 2em;
        font-weight: 600;
        margin-bottom: 2vh;
    }
    h3:hover::after {
        width: 100%;
    }
    h3:after {
        content: ""; /* This is necessary for the pseudo element to work. */ 
        display: block; /* This will put the pseudo element on its own line. */
        width: 5%; /* Change this to whatever width you want to have before hover. */
        padding-top: 20px; /* This creates some space between the element and the border. */
        border-bottom: 3px solid black; /* This creates the border. Replace black with whatever color you want. */
        transition: .5s; /* This establishes the amount of time in seconds the animation should take from start to finish */
    }


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const Projectwrapper = styled.div`
    min-height: 100vh;
    background-color: #f0faf9;
    padding: 2.5vh;
`;

const Projects = styled.div`
    min-height: 95vh;
    max-height: 95vh;
    border: 1px solid black;
    display: flex;
    background-color: #f0faf9;
`;
const ProjectDetail = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 1vw 0 0 1vw;
    h1{
        padding: 2vh;
        font-weight: 200;
        font-size: 2.3em;

    }
`;
const ProjectIcons = styled.div`
   margin-left: 1vh;
   
   svg{
    margin: 1vh;
   }
`;
const ProjectList = styled.div`
    width: 50%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const ProjectItem = styled.a`
    margin: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;

    &:hover{
        text-decoration-line: none;
    }

    @media (min-width: 1100px){
        flex-direction: row-reverse;
    }
;`
const ProjectItemHeader = styled.div`
margin: 2vh;
display: flex;
flex-direction: row-reverse;
justify-content: flex-start;
`;

const ProjectTitle = styled.div`
    font-weight: 200;
    font-size: 30px;
    text-align: right;


@media (min-width: 1100px){
    font-size: 60px;
}
`;

const ProjectHeader = styled.header`
    display: flex;
    flex-direction: column;
`;
const ProjectDescription = styled.div`
    display:flex;
    flex-direction: column;
    margin: 0 0 5vh 2vh;
    width: 30vw;
    p{
        line-height: 1.3;

    }
    h2{
        font-weight: bold;
    }
    font-size: 1.5vh;
`;

const ProjectInfo = styled.div`
@media (min-width: 1280px){
    margin-top: 0;
}
    margin-top: 5px;
`;

const ContactWrapper = styled.div`
    padding-left: 5vw;
    display: flex;
    border: 1px solid red;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    background-color: #1a1919;
    color: #ece7e1;


`;

const ContactDescription = styled.div`
    padding-left: 1vw;
    margin-bottom: 2.3vw;
    a:hover{
        text-decoration-line: none;
    }
`;

const ContactHeader = styled.p`
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: opacity 500ms ease-in;
    font-family: 'Grandslang roman', sans-serif;
    font-size: 14vw;
    line-height: 1em;
    font-weight: 300;

`;